import { atom, createStore } from 'jotai';
import { atomWithStorage } from 'jotai/utils';

import { DataElement, DataSink, Metadata } from '~/ts-types';

export const jotaiStore = createStore();

export const currentOrganizationIdAtom = atomWithStorage(
  `__HOUNDDOG_CURRENT_ORGANIZATION_ID_${import.meta.env.VITE_HOUNDDOG_ENV}`,
  '',
  undefined,
  { getOnInit: true },
);
export const metadataAtom = atom<Metadata | null>(null);
export const dataElementsAtom = atom<DataElement[] | null>(null);
export const dataSinksAtom = atom<DataSink[] | null>(null);
export const repositoryImportsReposSelectedAtom = atom<string[] | null>(null);
