import { useAuthInfo } from '@propelauth/react';

import type { Permission } from '~/ts-types';

import { useMetadata } from './use-metadata';

// We'll check by Organization name so it works across environments
const PREVIEW_ORGANIZATIONS = ['HoundDog', 'PentestIQ'];

const useAuthorization = () => {
  const { metadata } = useMetadata();
  const orgId = metadata?.currentUser.orgId;
  const orgName = metadata?.currentUser.orgName || '';
  const authInfo = useAuthInfo();

  // util console.log to see what permissions are available to current user - Do not remove
  // console.log(authInfo.orgHelper?.getOrgByName('HoundDog')?.userPermissions);

  //
  // hasPermission
  //
  const hasPermission = (permission: Permission) => {
    if (!orgId) {
      return false;
    }
    return !!authInfo.accessHelper?.hasPermission(orgId, permission);
  };

  //
  // isPreviewOrg
  //
  const isPreviewOrg = PREVIEW_ORGANIZATIONS.includes(orgName);

  return { isPreviewOrg, hasPermission } as const;
};

export { useAuthorization };
