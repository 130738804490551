import { IAuthClient, createClient } from '@propelauth/javascript';
import invariant from 'tiny-invariant';

import {
  BackendError,
  Broker,
  DataElement,
  DataElementOccurrence,
  DataElementOccurrencePatchData,
  DataElementPatchData,
  DataElementPostData,
  DataElementVisualization,
  DataSink,
  EmailSettings,
  EmailSettingsPatchData,
  JiraSettings,
  JiraSettingsPatchData,
  Metadata,
  OrganizationScannerSettingsPatchData,
  Paged,
  RepositoryImportsPostData,
  RepositoryImportsFinishPostData,
  RepositoriesPatchData,
  Repository,
  RepositoryImport,
  RepositoryPatchData,
  RopaPatchData,
  RopaPostData,
  RopaReport,
  Sanitizer,
  SanitizerPatchData,
  SanitizerPostData,
  ScanRule,
  ScanRuleUpsertData,
  SlackSettings,
  SlackSettingsPatchData,
  Vulnerability,
  VulnerabilitiesPatchData,
  OrganizationScannerSettings,
  RepositorySummary,
} from '~/ts-types';

import {
  getStoredOrganizationId,
  isObject,
  transformArrayToIdsQueryParams,
} from './utils';
const BASE_API_URL = import.meta.env.VITE_BASE_API_URL;

//
//
//
// ACCESS TOKEN INITIALIZATION
//
//
//
// A way to access the access token outside a React Component realm because it is used in clientLoaders
let authClient: IAuthClient | null = null;
await (async () => {
  authClient = createClient({
    authUrl: import.meta.env.VITE_AUTH_URL,
    enableBackgroundTokenRefresh: true,
  });
})();

//
//
//
// BASE FETCH
//
//
//
type AllowedFetchBodies =
  | Array<string> // For DELETE requests
  | VulnerabilitiesPatchData
  | DataElementPatchData[]
  | DataElementOccurrencePatchData
  | JiraSettingsPatchData
  | EmailSettingsPatchData
  | SlackSettingsPatchData
  | OrganizationScannerSettingsPatchData
  | RepositoryPatchData
  | RepositoriesPatchData
  | RopaPostData
  | RopaPatchData
  | SanitizerPatchData
  | SanitizerPostData
  | ScanRuleUpsertData
  | RepositoryImportsPostData
  | RepositoryImportsFinishPostData;
async function baseFetch(
  path: string,
  method: 'GET' | 'POST' | 'PATCH' | 'DELETE' = 'GET',
  contentType?: string,
  body?: AllowedFetchBodies,
) {
  invariant(authClient, 'PropelAuth Auth Client not initialized');
  const authInfo = await authClient.getAuthenticationInfoOrNull();
  invariant(authInfo, 'PropelAuth Auth Info not initialized');

  const userOrganizations = authInfo?.orgHelper?.getOrgs() || [];
  const storedOrganizationId = getStoredOrganizationId(userOrganizations);

  const headers = new Headers();
  headers.set('Authorization', `Bearer ${authInfo.accessToken}`);
  headers.set('hounddog-org-id', storedOrganizationId);
  if (contentType) {
    headers.set('Content-Type', contentType);
  }

  const response = await fetch(`${BASE_API_URL}${path}`, {
    method,
    headers,
    body: body ? JSON.stringify(body) : undefined,
  });

  // Early return if response is ok
  if (response.ok) {
    return response;
  }

  // Response not ok path
  if (
    response.status === 400 ||
    response.status === 409 ||
    response.status === 422
  ) {
    const backendError: BackendError = await response.json();
    const { detail } = backendError;
    if (typeof detail === 'string') {
      throw new Error(detail);
    }
    if (Array.isArray(detail)) {
      throw new Error(detail.map((d) => d.ctx.error).join('\n'));
    }
    if (isObject(detail)) {
      throw new Error(Object.values(detail).join('\n'));
    }
  }
  if (response.status === 401) {
    // A valid PropelAuth access token / API key was not provided (missing, incorrect or expired)
    // the user authentication failed and must login again
    await authClient.logout(true);
    return;
  }
  if (response.status === 403) {
    throw new Error('Unauthorized access.');
  }
  if (response.status === 404) {
    return null;
  }
  // catch any other error
  throw new Error('Something went wrong.');
}

//
// FETCH JSON
//
async function fetchJson<T>(
  path: string,
  method: 'GET' | 'POST' | 'PATCH' | 'DELETE' = 'GET',
  body?: AllowedFetchBodies,
) {
  const response = await baseFetch(path, method, 'application/json', body);
  if (!response) {
    return null;
  }
  const jsonRes: T = await response.json();
  return jsonRes;
}

//
// FETCH TEXT
//
async function fetchText(path: string, contentType = 'text/csv') {
  const response = await baseFetch(path, 'GET', contentType);
  if (!response) {
    return null;
  }
  return await response.text();
}

//
// FETCH BLOB
//
async function fetchBlob(path: string, contentType = 'application/pdf') {
  const response = await baseFetch(path, 'GET', contentType);
  if (!response) {
    return null;
  }
  return await response.blob();
}

//
//
//
// METADATA
//
//
//
export const getMetadata = async () => {
  const res = await fetchJson<Metadata>('/metadata/');
  return res;
};

//
//
//
// REPOSITORIES
//
//
//
export const getRepositories = async (queryParams = '') => {
  const res = await fetchJson<Paged<Repository>>(
    `/repositories/?${queryParams}`,
  );
  return res;
};
export const getRepositorySummaries = async (queryParams = '') => {
  const res = await fetchJson<Paged<RepositorySummary>>(
    `/repository-summaries/?${queryParams}`,
  );
  return res;
};
export const getRepository = async (repoName = '') => {
  const res = await fetchJson<Repository>(`/repositories/${repoName}/`);
  return res;
};
export const deleteRepository = async (id: string) => {
  const res = await baseFetch(`/repositories/${id}/`, 'DELETE');
  return res;
};
export const patchRepository = async (
  id: string,
  patchData: RepositoryPatchData,
) => {
  const res = await fetchJson<Repository>(
    `/repositories/${id}/`,
    'PATCH',
    patchData,
  );
  return res;
};
export const patchRepositories = async (patchData: RepositoriesPatchData) => {
  const res = await fetchJson<Repository[]>(
    `/repositories/`,
    'PATCH',
    patchData,
  );
  return res;
};

//
//
//
// VULNERABILITIES
//
//
//
export const getVulnerabilities = async (queryParams: string) => {
  const res = await fetchJson<Paged<Vulnerability>>(
    `/vulnerabilities/?${queryParams}`,
  );
  return res;
};
export const getVulnerability = async (id: string) => {
  const res = await fetchJson<Vulnerability>(`/vulnerabilities/${id}/`);
  return res;
};
export const patchVulnerability = async (
  patchData: VulnerabilitiesPatchData,
) => {
  const res = await fetchJson<Vulnerability>(
    `/vulnerabilities/`,
    'PATCH',
    patchData,
  );
  return res;
};
export const postJiraVulnerability = async (id: string) => {
  const res = await fetchJson<Vulnerability>(
    `/vulnerabilities/${id}/jira-issue/`,
    'POST',
  );
  return res;
};

//
//
//
// DATA ELEMENTS
//
//
//
export const getDataElements = async (queryParams: string) => {
  const res = await fetchJson<Paged<DataElement>>(
    `/data-elements/?${queryParams}`,
  );
  return res;
};
export const getDataElement = async (id: string) => {
  const res = await fetchJson<DataElement>(`/data-elements/${id}/`);
  return res;
};
export const patchDataElements = async (patchData: DataElementPatchData[]) => {
  const res = await fetchJson<DataElement[]>(
    `/data-elements/`,
    'PATCH',
    patchData,
  );
  return res;
};
export const postDataElement = async (postData: DataElementPostData) => {
  const res = await fetchJson<DataElement[]>(
    `/data-elements/`,
    'POST',
    postData,
  );
  return res;
};
export const deleteDataElements = async (deleteData: Array<string>) => {
  const queryParams = transformArrayToIdsQueryParams(deleteData);
  const res = await baseFetch(
    `/data-elements/?${queryParams}`,
    'DELETE',
    undefined,
    deleteData,
  );
  return res;
};

//
//
//
// DATA ELEMENT OCCURRENCES
//
//
//
export const getDataElementOccurrences = async (queryParams: string) => {
  const res = await fetchJson<Paged<DataElementOccurrence>>(
    `/data-element-occurrences/?${queryParams}`,
  );
  return res;
};
export const getDataElementOccurrence = async (id: string) => {
  const res = await fetchJson<DataElementOccurrence>(
    `/data-element-occurrences/${id}/`,
  );
  return res;
};
export const patchDataElementOccurrence = async (
  patchData: DataElementOccurrencePatchData,
) => {
  const res = await fetchJson<DataElementOccurrence>(
    `/data-element-occurrences/`,
    'PATCH',
    patchData,
  );
  return res;
};

//
//
//
// DATA ELEMENT VISUALIZATION
//
//
//
export const getDataElementVisualization = async (queryParams: string) => {
  const res = await fetchJson<DataElementVisualization>(
    `/visualizations/data-elements/?${queryParams}`,
  );
  return res;
};

//
//
//
// ORGANIZATION SETTINGS
//
//
//
// JIRA
export const getJiraSettings = async () => {
  const res = await fetchJson<JiraSettings>('/settings/jira/');
  return res;
};
export const patchJiraSettings = async (patchData: JiraSettingsPatchData) => {
  const res = await fetchJson<JiraSettings>(
    `/settings/jira/`,
    'PATCH',
    patchData,
  );
  return res;
};

// EMAIL
export const getEmailSettings = async () => {
  const res = await fetchJson<EmailSettings>('/settings/email/');
  return res;
};
export const patchEmailSettings = async (patchData: EmailSettingsPatchData) => {
  const res = await fetchJson<EmailSettings>(
    `/settings/email/`,
    'PATCH',
    patchData,
  );
  return res;
};

// BROKER
export const getBrokersSettings = async () => {
  const res = await fetchJson<{ isBrokerEnabled: boolean }>(
    '/settings/brokers/',
  );
  return res;
};

// SLACK
export const getSlackSettings = async () => {
  const res = await fetchJson<SlackSettings>('/settings/slack/');
  return res;
};
export const patchSlackSettings = async (patchData: SlackSettingsPatchData) => {
  const res = await fetchJson<SlackSettings>(
    `/settings/slack/`,
    'PATCH',
    patchData,
  );
  return res;
};
export const getSlackOauthStart = async () => {
  const res = await fetchJson<{ authUrl: string }>('/slack/oauth/start/');
  return res;
};
export const getSlackOauthFinish = async (queryParams: string) => {
  const res = await baseFetch(`/slack/oauth/finish/${queryParams}`);
  return res;
};

// SCANNER
export const getOrganizationScannerSettings = async () => {
  const res =
    await fetchJson<OrganizationScannerSettings>('/settings/scanner/');
  return res;
};
export const patchOrganizationScannerSettings = async (
  patchData: OrganizationScannerSettingsPatchData,
) => {
  const res = await fetchJson<OrganizationScannerSettings>(
    `/settings/scanner/`,
    'PATCH',
    patchData,
  );
  return res;
};

//
//
//
// ROPA
//
//
//
export const getRopaReports = async () => {
  const res = await fetchJson<Paged<RopaReport>>('/ropa-reports/');
  return res;
};
export const postRopaReport = async (postData: RopaPostData) => {
  const res = await fetchJson<RopaReport>(`/ropa-reports/`, 'POST', postData);
  return res;
};
export const patchRopaReport = async (id: string, patchData: RopaPatchData) => {
  const res = await fetchJson<RopaReport>(
    `/ropa-reports/${id}/`,
    'PATCH',
    patchData,
  );
  return res;
};
export const exportRopaReportCsv = async (id: string) => {
  const res = await fetchText(`/ropa-reports/${id}/csv/`);
  return res;
};
export const exportRopaReportPdf = async (id: string) => {
  const res = await fetchBlob(`/ropa-reports/${id}/pdf/`);
  return res;
};

//
//
//
// SANITIZERS
//
//
//
export const getSanitizers = async (queryParams: string) => {
  const res = await fetchJson<Paged<Sanitizer>>(`/sanitizers/?${queryParams}`);
  return res;
};
export const getSanitizer = async (id: string) => {
  const res = await fetchJson<Sanitizer>(`/sanitizers/${id}/`);
  return res;
};
export const postSanitizer = async (postData: SanitizerPostData) => {
  const res = await fetchJson<Sanitizer[]>(`/sanitizers/`, 'POST', postData);
  return res;
};
export const patchSanitizers = async (patchData: SanitizerPatchData) => {
  const res = await fetchJson<Sanitizer[]>(`/sanitizers/`, 'PATCH', patchData);
  return res;
};
export const deleteSanitizers = async (deleteData: Array<string>) => {
  const queryParams = transformArrayToIdsQueryParams(deleteData);
  const res = await baseFetch(
    `/sanitizers/?${queryParams}`,
    'DELETE',
    undefined,
    deleteData,
  );
  return res;
};

//
//
//
// DATA SINKS
//
//
//
export const getDataSinks = async (queryParams: string) => {
  const res = await fetchJson<Paged<DataSink>>(`/data-sinks/?${queryParams}`);
  return res;
};

//
//
//
// SCAN RULES
//
//
//
export const getScanRules = async () => {
  const res = await fetchJson<Paged<ScanRule>>('/scan-rules/');
  return res;
};
export const upsertScanRules = async (upsertData: ScanRuleUpsertData) => {
  const res = await fetchJson<ScanRule[]>(`/scan-rules/`, 'POST', upsertData);
  return res;
};

//
//
//
// BROKERS
//
//
//
export const getBrokers = async () => {
  const res = await fetchJson<Broker[]>('/brokers/');
  return res;
};

//
//
//
// REPOSITORIES IMPORTS
//
//
//
export const getRepositoryImports = async (id: string) => {
  const res = await fetchJson<RepositoryImport>(`/repository-imports/${id}`);
  return res;
};
export const postRepositoryImports = async (
  postData: RepositoryImportsPostData,
) => {
  const res = await fetchJson<{ id: string }>(
    `/repository-imports/`,
    'POST',
    postData,
  );
  return res;
};
export const postRepositoryImportsFinish = async (
  id: string,
  postData: RepositoryImportsFinishPostData,
) => {
  const res = await fetchJson<RepositoryImport>(
    `/repository-imports/${id}/finish/`,
    'POST',
    postData,
  );
  return res;
};
export const postRepositoryImportsCancel = async (id: string) => {
  const res = await baseFetch(`/repository-imports/${id}/cancel/`, 'POST');
  return res;
};
